import React, { useState, useEffect } from 'react';
import * as S from './styles';
import principal from '../../images/principal.jpeg';

const Section1 = () => {
  const fullText = 'Olá,\nEu sou Vanise!';
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      if (displayText.length < fullText.length) {
        setDisplayText(fullText.slice(0, displayText.length + 1));
      } else {
        clearInterval(interval);
      }
    }, 150); 

    return () => clearInterval(interval); 
  }, [displayText, fullText]);

  return (
    <S.Container>
      <img src={principal} alt="principal" />
      <S.ContainerTitle>
        <h1>{displayText.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}<br/>
          </React.Fragment>
        ))}</h1>
      </S.ContainerTitle>
    </S.Container>
  );
};

export default Section1;
