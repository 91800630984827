import React from "react";
import * as S from "./styles";
const Section2 = () => {
  return (
    <S.Container>
      <div>

      <h2>Quem eu sou e o que faço.</h2>
      <div>
        <p>
          <span>Meu nome é Vanise Helena. </span>
          Nasci e fui criada na rua Vai e Volta, bairro da Glória, onde ainda
          resido. Venho de uma família humilde e sou a primogênita de quatro
          filhos da Leny, uma mulher batalhadora que sustentou a família
          vendendo roupas de porta em porta. Sou Técnica em Enfermagem e atuo há
          20 anos na saúde pública do município de Santos Dumont. Durante minha
          carreira, adquiri vasta experiência no Programa de Saúde da Família e
          atualmente trabalho na Sala de Imunização.
        </p>
      </div>
      </div>
    </S.Container>
  );
};

export default Section2;
