import React from "react";
import * as S from "./styles";
import { CiInstagram } from "react-icons/ci";
import { FaFacebookSquare } from "react-icons/fa";
const Section4 = () => {
  return (
    <S.Container>
      <div>
        <h1>Me Siga nas Redes Sociais </h1>
        <p>Email: contato@vanise.com.br</p>
        <div>
          <a
            href="https://www.instagram.com/vanise.helena?igsh=MTI3bzNqbGVrcXlpMA=="
            target="_blank"
            rel="noopener noreferrer"
          >
            <CiInstagram />
          </a>
          <a
            href="https://www.facebook.com/share/TsHazkghuJGbRX2c/?mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookSquare />
          </a>
        </div>
      </div>
    </S.Container>
  );
};

export default Section4;
