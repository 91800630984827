import styled from "styled-components";
import backgroundImage from "../../images/fundo.png";
export const Container = styled.div`
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  display: flex;
  gap: 4rem;
  img {
    height: 100vh;
    border-radius: 0 7rem 7rem 0;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    img {
      width: 100%;
      height: 60%;
      border-radius: 0;
    }
  }
`;
export const ContainerTitle = styled.div`
  h1 {
    color: #fff;
    font-size: 6.25rem;
    font-family: "Open Sans", sans-serif;
  }
  margin-bottom: 2rem;
  
  @media (max-width: 768px) {
    padding: 1rem;
    margin: 0;
    h1 {
    color: #fff;
    font-size: 4rem;
    font-family: "Open Sans", sans-serif;
  }
  }
`;
