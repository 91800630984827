import Section1 from "./pages/Section1/section";
import Section2 from "./pages/Section2/section";
import Section3 from "./pages/Section3/section";
import Section4 from "./pages/Section4/section";

function App() {
  return (
    <>
      <Section1 />
      <Section2 />
      <Section4 />
    </>
  );
}

export default App;
