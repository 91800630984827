import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100%;

  justify-content: center;
  align-items: center;
  background-color: gainsboro;
`;
