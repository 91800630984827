import styled from "styled-components";

export const Container = styled.div`
  height: 40vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding: 1rem;
  display: flex;

  svg {
    color: #fff;
    font-size: 2rem;
  }
  > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    div {
      display: flex;
      gap: 1rem;
    }
  }
  h2,
  h1,
  p {
    font-family: "Open Sans", sans-serif;
    color: #fff;
  }
  h1 {
    color: #fff;
    font-size: 2rem;
    line-height: 3rem;
  }
  @media (max-width: 768px) {
  
  }
`;
