import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 1rem;
  > div {
    padding-left: 4rem;
    width: 60%;
  }
  h2,
  p {
    font-family: "Open Sans", sans-serif;
    color: #000;
  }
  h2 {
    font-size: 2rem;
    margin: 2rem 0 3rem;
    color: #000;
    font-weight: 400;
  }
  p {
    color: #424242;
    font-size: 2rem;
    line-height: 2.5rem;
  }
  span {
    font-weight: 600;
    color: #000;
  }

  @media (max-width: 768px) {
    > div {
      padding-left: 0;
      width: 100%;
    }
    padding: 1rem;
    margin: 0;
    height: 110vh;
    h2 {
      font-size: 2rem;
      margin: 2rem 0 3rem;
    }
    h1 {
      color: #000;
      font-size: 3rem;
      font-family: "Open Sans", sans-serif;
    }
    p {
      color: #424242;
      font-size: 1.5rem;
      line-height: 2.5rem;
    }
  }
`;
